import React from "react"
import { Link } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <ParallaxProvider>
      <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
        <header className="site-head">
          <div className="site-head-container">
            <a
              className="nav-burger"
              href={`#`}
              onClick={() => setToggleNav(!toggleNav)}
            >
              <div
                className="hamburger hamburger--collapse"
                aria-label="Menu"
                role="button"
                aria-controls="navigation"
              >
                <div className="hamburger-box">
                  <div className="hamburger-inner" />
                </div>
              </div>
            </a>
            <nav id="swup" className="site-head-left">
              <ul className="nav" role="menu">
                <li className="nav-home nav-current" role="menuitem">
                  <Link to={`/`}>Home</Link>
                </li>
              </ul>
            </nav>
            <div className="site-head-center">
              <Link className="site-head-logo" to={`/`}>
                {title}
              </Link>
            </div>
            <div className="site-head-right">
              <a
                href="https://www.producthunt.com/posts/minima-simple-iphone-12-mockups?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-minima-simple-iphone-12-mockups"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=270835&theme=dark"
                  alt="minima | minimalistic iPhone 12 mockups - 📱 Minimalistic, customisable isometric iPhone 12 mockups | Product Hunt"
                  style={{ width: "250px", height: "54px" }}
                  width="250"
                  height="54"
                />
              </a>
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main">
          <div id="swup" className="transition-fade">
            {children}
          </div>
        </main>
        <footer className="site-foot">
          &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>{" "}
          &mdash; Made by{" "}
          <a
            href="https://palapa.tech"
            target="_blank"
            rel="noopener noreferrer"
          >
            Palapa
          </a> <br></br> <Link to="/license">License</Link> | <Link to="/privacy">Privacy</Link>
        </footer>
      </div>
    </ParallaxProvider>
  )
}

export default Layout
